import React from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Button,
} from "reactstrap"
import Header from "../../components/External/Header"
import Footer from "../../components/External/Footer"
import heroImage from "../../assets/images/wages.jpeg"
import feature1 from "../../assets/images/payroll.jpeg"
import feature2 from "../../assets/images/paycompliance.jpeg"
import feature3 from "../../assets/images/payreports.jpeg"
import "./WagesSalaries.css"

const WagesSalaries = () => {
              const BookADemo = () => {
                location.href = "/book"
              }

              const ContactUs = () => {
                location.href = "/contactus"
              }
  return (
    <>
      <Header />

      <div className="page-content">
        {/* Hero Section */}
        <section
          className="hero-section text-white text-center py-5"
          style={{
            backgroundImage: `url(${heroImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        >
          <Container>
            <h1 className="display-4">
              Simplify Wages and Salaries Management
            </h1>
            <p className="lead">
              Manage payroll with ease, accuracy, and efficiency using our
              comprehensive solution.
            </p>
            <Button color="primary" size="lg">
              Learn More
            </Button>
          </Container>
        </section>

        {/* Features Section */}
        <section className="features-section my-5">
          <Container>
            <h2 className="text-center mb-4">Key Features</h2>
            <Row>
              <Col md="4" className="mb-4">
                <Card className="h-100 shadow border-0">
                  <img
                    src={feature1}
                    alt="Feature 1"
                    className="card-img-top"
                  />
                  <CardBody>
                    <CardTitle tag="h5">Automated Payroll</CardTitle>
                    <CardText>
                      Process wages and salaries quickly and accurately with our
                      automated tools.
                    </CardText>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4" className="mb-4">
                <Card className="h-100 shadow border-0">
                  <img
                    src={feature2}
                    alt="Feature 2"
                    className="card-img-top"
                  />
                  <CardBody>
                    <CardTitle tag="h5">Compliance Checks</CardTitle>
                    <CardText>
                      Ensure compliance with tax regulations and labor laws to
                      avoid penalties.
                    </CardText>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4" className="mb-4">
                <Card className="h-100 shadow border-0">
                  <img
                    src={feature3}
                    alt="Feature 3"
                    className="card-img-top"
                  />
                  <CardBody>
                    <CardTitle tag="h5">Custom Reports</CardTitle>
                    <CardText>
                      Generate detailed payroll reports to gain insights and
                      ensure transparency.
                    </CardText>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>

        {/* Benefits Section */}
        <section className="benefits-section bg-light py-5">
          <Container>
            <h2 className="text-center mb-4">
              Benefits of Our Payroll Solution
            </h2>
            <Row>
              <Col md="6" className="mb-4">
                <h5>Time Efficiency</h5>
                <p>
                  Automate repetitive payroll tasks to save valuable time and
                  focus on strategic activities.
                </p>
              </Col>
              <Col md="6" className="mb-4">
                <h5>Accuracy and Reliability</h5>
                <p>
                  Reduce errors in wage calculations and ensure accurate
                  payments every time.
                </p>
              </Col>
              <Col md="6" className="mb-4">
                <h5>Employee Satisfaction</h5>
                <p>
                  Enhance employee satisfaction with timely and transparent
                  salary processing.
                </p>
              </Col>
              <Col md="6" className="mb-4">
                <h5>Scalability</h5>
                <p>
                  Manage payroll for businesses of any size, from startups to
                  large enterprises.
                </p>
              </Col>
            </Row>
          </Container>
        </section>

        {/* Testimonials Section */}
        <section className="testimonials-section my-5">
          <Container>
            <h2 className="text-center mb-4">What Our Clients Say</h2>
            <Row>
              <Col md="6" className="mb-4">
                <Card className="shadow border-0">
                  <CardBody>
                    <CardText>
                      "The payroll solution has streamlined our wage management
                      process. It's efficient and user-friendly."
                    </CardText>
                    <p className="text-muted text-end">
                      - Linda M., HR Manager
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6" className="mb-4">
                <Card className="shadow border-0">
                  <CardBody>
                    <CardText>
                      "Accurate and compliant payroll has never been easier. A
                      must-have tool for businesses!"
                    </CardText>
                    <p className="text-muted text-end">
                      - James T., Business Owner
                    </p>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>

        {/* Call-to-Action Section */}
        <section className="cta-section text-center py-5">
          <Container>
            <h2 className="mb-4">Ready to Simplify Payroll?</h2>
            <p className="lead mb-4">
              Contact us today to learn more about our payroll solutions.
            </p>
            <Button
              color="primary"
              size="lg"
              className="me-3"
              onClick={ContactUs}
            >
              Contact Us
            </Button>
            <Button color="secondary" size="lg" onClick={BookADemo}>
              Learn More
            </Button>
          </Container>
        </section>
      </div>

      <Footer />
    </>
  )
}

export default WagesSalaries
