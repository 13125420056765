import React, { useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import {
  Nav,
  NavItem,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  Button,
} from "reactstrap"
import { showRightSidebarAction, toggleLeftmenu } from "../../store/actions"
import { withTranslation } from "react-i18next"

import logo from "../../assets/images/caretip.jpg"
import "./Header.css" // Importing the CSS file

const Header = props => {
  const [dropdownOpen, setDropdownOpen] = useState({
    careProviders: false,
    agencies: false,
    aboutUs: false,
    resources: false,
  })

  const toggleDropdown = dropdownName => {
    setDropdownOpen(prevState => ({
      ...prevState,
      [dropdownName]: !prevState[dropdownName],
    }))
  }

    const bookADemo = () => {
      location.href = "/book"
    }

      const login = () => {
        location.href = "/login"
      }

  return (
    <React.Fragment>
      <header id="page-topbarx" className="header-shadow">
        <div className="navbar-header" style={{ fontSize: "16px" }}>
          <div className="d-flex align-items-center">
            <div className="navbar-brand-box">
              <Link to="/home" className="logo logo-dark">
                <img
                  src={logo}
                  alt="CareTip Logo"
                  className="rounded-circle"
                  height="84"
                />
              </Link>
            </div>

            {/* Mega Menu Start */}
            <Nav className="d-none d-lg-flex ms-4">
              <NavItem>
                <Dropdown
                  isOpen={dropdownOpen.careProviders}
                  toggle={() => toggleDropdown("careProviders")}
                >
                  <DropdownToggle tag="a" className="nav-link" caret>
                    For Care Providers <span className="caret-icon">▼</span>
                  </DropdownToggle>
                  <DropdownMenu>
                    <Link
                      to="/recruit"
                      className="dropdown-item"
                      style={{ fontSize: "16px" }}
                    >
                      Recruitment
                    </Link>
                    <Link
                      to="/wages"
                      className="dropdown-item"
                      style={{ fontSize: "16px" }}
                    >
                      Wages/salaries
                    </Link>
                    <Link
                      to="/invoices"
                      className="dropdown-item"
                      style={{ fontSize: "16px" }}
                    >
                      Invoices
                    </Link>
                  </DropdownMenu>
                </Dropdown>
              </NavItem>
              <NavItem>
                <Dropdown
                  isOpen={dropdownOpen.agencies}
                  toggle={() => toggleDropdown("agencies")}
                >
                  <DropdownToggle tag="a" className="nav-link" caret>
                    For Agencies <span className="caret-icon">▼</span>
                  </DropdownToggle>
                  <DropdownMenu>
                    <Link
                      to="/staffing"
                      className="dropdown-item"
                      style={{ fontSize: "16px" }}
                    >
                      Staffing Service
                    </Link>
                    <Link
                      to="/rota"
                      className="dropdown-item"
                      style={{ fontSize: "16px" }}
                    >
                      Rota management
                    </Link>
                    <Link
                      to="/digitaltime"
                      className="dropdown-item"
                      style={{ fontSize: "16px" }}
                    >
                      Digital Timesheet
                    </Link>
                  </DropdownMenu>
                </Dropdown>
              </NavItem>
              <NavItem>
                <Dropdown
                  isOpen={dropdownOpen.aboutUs}
                  toggle={() => toggleDropdown("aboutUs")}
                >
                  <DropdownToggle tag="a" className="nav-link" caret>
                    About Us <span className="caret-icon">▼</span>
                  </DropdownToggle>
                  <DropdownMenu>
                    <Link
                      to="/aboutus"
                      className="dropdown-item"
                      style={{ fontSize: "16px" }}
                    >
                      Our Story
                    </Link>
                    <Link
                      to="/team"
                      className="dropdown-item"
                      style={{ fontSize: "16px" }}
                    >
                      Our Team
                    </Link>
                  </DropdownMenu>
                </Dropdown>
              </NavItem>
              <NavItem>
                <Dropdown
                  isOpen={dropdownOpen.resources}
                  toggle={() => toggleDropdown("resources")}
                >
                  <DropdownToggle tag="a" className="nav-link" caret>
                    Resources <span className="caret-icon">▼</span>
                  </DropdownToggle>
                  <DropdownMenu>
                    <Link
                      to="/blog"
                      className="dropdown-item"
                      style={{ fontSize: "16px" }}
                    >
                      Blog
                    </Link>
                    <Link
                      to="/guide"
                      className="dropdown-item"
                      style={{ fontSize: "16px" }}
                    >
                      Guides
                    </Link>
                  </DropdownMenu>
                </Dropdown>
              </NavItem>
            </Nav>
            {/* Mega Menu End */}
          </div>

          {/* Right side buttons */}
          <div className="d-flex ms-auto">
            <Button
              color="primary"
              style={{ fontSize: "16px" }}
              onClick={login}
              outline
              className="rounded-pill me-2"
            >
              Login
            </Button>
            <Button
              color="primary"
              style={{ fontSize: "16px" }}
              onClick={bookADemo}
              className="rounded-pill"
            >
              Schedule a Demo
            </Button>
          </div>
        </div>
      </header>
    </React.Fragment>
  )
}

Header.propTypes = {
  showRightSidebarAction: PropTypes.func,
  toggleLeftmenu: PropTypes.func,
}

const mapStatetoProps = state => {
  const { showRightSidebar, leftMenu } = state.Layout
  return { showRightSidebar, leftMenu }
}

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
})(withTranslation()(Header))
