import React from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Button,
} from "reactstrap"
import Header from "../../components/External/Header"
import Footer from "../../components/External/Footer"
import heroImage from "../../assets/images/Invoices.jpeg"
import feature1 from "../../assets/images/autoinvoice.jpeg"
import feature2 from "../../assets/images/templatedinv.jpg"
import feature3 from "../../assets/images/realtimeinv.jpeg"
import "./Invoices.css"

const Invoices = () => {
              const BookADemo = () => {
                location.href = "/book"
              }

              const ContactUs = () => {
                location.href = "/contactus"
              }
  return (
    <>
      <Header />

      <div className="page-content">
        {/* Hero Section */}
        <section
          className="hero-section text-white text-center py-5"
          style={{
            backgroundImage: `url(${heroImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        >
          <Container>
            <h1 className="display-4">Simplify Your Invoicing Process</h1>
            <p className="lead">
              Manage invoices with ease, accuracy, and efficiency using our
              comprehensive solution.
            </p>
            <Button color="primary" size="lg">
              Learn More
            </Button>
          </Container>
        </section>

        {/* Features Section */}
        <section className="features-section my-5">
          <Container>
            <h2 className="text-center mb-4">Key Features</h2>
            <Row>
              <Col md="4" className="mb-4">
                <Card className="h-100 shadow border-0">
                  <img
                    src={feature1}
                    alt="Feature 1"
                    className="card-img-top"
                  />
                  <CardBody>
                    <CardTitle tag="h5">Automated Invoice Generation</CardTitle>
                    <CardText>
                      Create accurate invoices in seconds with our automated
                      tools.
                    </CardText>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4" className="mb-4">
                <Card className="h-100 shadow border-0">
                  <img
                    src={feature2}
                    alt="Feature 2"
                    className="card-img-top"
                  />
                  <CardBody>
                    <CardTitle tag="h5">Customizable Templates</CardTitle>
                    <CardText>
                      Personalize invoices with your company’s branding and
                      layout preferences.
                    </CardText>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4" className="mb-4">
                <Card className="h-100 shadow border-0">
                  <img
                    src={feature3}
                    alt="Feature 3"
                    className="card-img-top"
                  />
                  <CardBody>
                    <CardTitle tag="h5">Real-Time Tracking</CardTitle>
                    <CardText>
                      Monitor invoice status and receive alerts for overdue
                      payments.
                    </CardText>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>

        {/* Benefits Section */}
        <section className="benefits-section bg-light py-5">
          <Container>
            <h2 className="text-center mb-4">
              Benefits of Our Invoice Solution
            </h2>
            <Row>
              <Col md="6" className="mb-4">
                <h5>Improved Cash Flow</h5>
                <p>
                  Ensure timely payments with automated reminders and tracking.
                </p>
              </Col>
              <Col md="6" className="mb-4">
                <h5>Time Efficiency</h5>
                <p>
                  Automate invoice creation and reduce manual errors to save
                  time.
                </p>
              </Col>
              <Col md="6" className="mb-4">
                <h5>Enhanced Transparency</h5>
                <p>
                  Provide clients with clear and detailed invoices for better
                  communication.
                </p>
              </Col>
              <Col md="6" className="mb-4">
                <h5>Custom Reports</h5>
                <p>
                  Generate reports to gain insights into invoicing trends and
                  payment history.
                </p>
              </Col>
            </Row>
          </Container>
        </section>

        {/* Testimonials Section */}
        <section className="testimonials-section my-5">
          <Container>
            <h2 className="text-center mb-4">What Our Clients Say</h2>
            <Row>
              <Col md="6" className="mb-4">
                <Card className="shadow border-0">
                  <CardBody>
                    <CardText>
                      "The automated invoicing system has streamlined our
                      billing process. It's efficient and reliable."
                    </CardText>
                    <p className="text-muted text-end">
                      - Sarah M., Business Owner
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6" className="mb-4">
                <Card className="shadow border-0">
                  <CardBody>
                    <CardText>
                      "Tracking overdue invoices is now a breeze. Highly
                      recommend this solution!"
                    </CardText>
                    <p className="text-muted text-end">
                      - David T., Accountant
                    </p>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>

        {/* Call-to-Action Section */}
        <section className="cta-section text-center py-5">
          <Container>
            <h2 className="mb-4">Ready to Simplify Your Invoicing?</h2>
            <p className="lead mb-4">
              Contact us today to learn more about our invoicing solutions.
            </p>
            <Button
              color="primary"
              size="lg"
              className="me-3"
              onClick={ContactUs}
            >
              Contact Us
            </Button>
            <Button color="secondary" size="lg" onClick={BookADemo}>
              Learn More
            </Button>
          </Container>
        </section>
      </div>

      <Footer />
    </>
  )
}

export default Invoices
